.links-bar {
	position: absolute;
	bottom: 3rem;
	left: 3rem;
	display: flex;

	// to counter the gradient in tech
	z-index: 100;

	a:first-child {
		margin-right: 0.8rem;
	}

	img {
		width: 21px;
		height: 21px;
	}

	img:hover {
		transform: scale(1.1);
	}
}

@media only screen and (max-width: 768px) {
	.links-bar {
		bottom: 1.8rem;
		left: 2rem;
	}
}

@media only screen and (max-width: 415px) {
	.links-bar {
		display: none;
	}
}