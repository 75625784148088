.nav-wrapper {
	width: 100%;
	position: absolute;
	bottom: 0;
	margin-bottom: 3rem;
	display: flex;
	justify-content: center;

	// to counter the gradient in tech
	z-index: 100;

	nav {
		width: fit-content;
		text-align: center;

		a {
			margin: 0 1rem;
			text-decoration: none;
			font-weight: 400;
			letter-spacing: 0;
		}

		.active {
			color: rgb(87, 229, 236) !important;
		}

		a:hover {
			cursor: pointer;
			color: white;

			&.active {
				color: rgb(87, 229, 236) !important;
			}
		}

		.underline {
			position: relative;
			height: 1px;
			width: 2.5rem;
			margin: 0.625rem 1rem 0 1rem;
			background-color: rgb(87, 229, 236);
			left: 0
		}
	}
}

@media only screen and (max-width: 768px) {
	.nav-wrapper {
		margin-bottom: 0;
		padding: 1rem 0;
		background-color: rgba(0, 0, 0, 0.9);
	}
}