.tech {
	height: 100vh;
	min-width: 100vw;

	.row {
		height: 100%;
		min-width: 100vw;
		padding: 0;
		margin: 0;

		.header-col {
			display: flex;
			justify-content: center;

			.header-box {
				padding-top: 14rem;

				.subheading-box {
					display: flex;
					flex-direction: row;

					.line {
						border-top: 4px solid white;
						width: 30px;
						margin-right: 2rem;
						align-self: center;
					}
				}
			}

			@media only screen and (max-width: 768px) {
				.header-box {
					padding: 2rem 2.5rem 0 2.5rem;
					margin-bottom: 4rem;
				}
			}
		}

		@media only screen and (max-width: 768px) {
			.header-col {
				justify-content: start;
			}
		}

		.content-col {
			padding-right: 3rem;

			.row {
				min-width: 100%;
				height: 100vh;
				overflow: auto;
				padding-bottom: 7rem;

				.right {
					border-right: 2px solid rgb(30, 30, 30);
				}

				.left {
					border-left: 2px solid rgb(30, 30, 30);
				}

				.left, .right {
					overflow: hidden;
					padding: 0;
					min-height: calc(100vh / 3);

					.icon {
						align-self: flex-start !important;
					}
				}

				@media only screen and (max-width: 768px) {
					.left, .right {
						border: none;
					}
				}

				@media only screen and (max-width: 576px) {
					.left, .right {
						min-height: calc(100vh / 4);
						margin-bottom: 2rem;
					}
				}

				.text-box {
					height: 100%;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					padding: 1rem;

					.subheading {
						color: rgba(255, 255, 255, 0.5) !important;
						margin: 0;
					}

					.code-subheading {
						color: rgba(255, 255, 255, 0.7) !important;
						margin: 0;
					}

					@media only screen and (max-width: 768px) {
						p {
							margin-bottom: 1rem !important;
						}
					}

					.icon {
						align-self: flex-end;

						img {
							width: 22px;
						}

						img:hover {
							cursor: pointer;
							transform: scale(1.1);
						}
					}
				}
			}

			@media only screen and (max-width: 768px) {
				.row {
					height: fit-content;
					overflow: scroll;
				}
			}

			.github {
				background: url("../assets/images/cody.jpg");
				background-size: cover;
				background-repeat: no-repeat;
			}

			.apps {
				background: url("../assets/images/apps-1.png");
				background-size: cover;
				background-repeat: no-repeat;
			}

			.blog {
				background: url("../assets/images/w-2.jpg");
				background-size: cover;
				background-repeat: no-repeat;
			}

			.kofi {
				background: url("../assets/images/qrcode.webp");
				background-color: white;
				background-size: contain;
				background-repeat: no-repeat;
			}

			@media only screen and (max-width: 576px) {
				.github, .apps, .blog, .kofi {
					display: none;
				}
			}

			.overlay {
				display: flex;
				width: auto;
				height: 100%;
				background-color: rgba(8, 8, 8, 0.7);
				opacity: 0;
				-webkit-transition: opacity ease .5s;
				-moz-transition: .5s ease;
				-o-transition: .5s ease;
				transition: .5s ease;

				button {
					align-self: center;
					margin: auto;
					padding: 0.5rem 1.5rem;
					background-color: transparent;
					border: 1px solid white;
					border-radius: 10px;

					a {
						color: white;
						text-decoration: none;
					}
				}
			}

			.github:hover, .apps:hover, .blog:hover, .kofi:hover {
				.overlay {
					opacity: 1;
				}
			}
		}

		@media only screen and (max-width: 768px) {
			.content-col {
				padding: 0 3rem;
			}
		}
	}
}

.tech::before {
	background: linear-gradient(rgba(8, 8, 8, 0.7), rgb(0,0,0,0), rgb(0,0,0,0), rgb(0,0,0,0), rgb(0,0,0,0), rgb(0,0,0,0), rgb(0,0,0,0), rgb(0,0,0,0), rgb(0,0,0,0), rgb(0,0,0,0)),
		linear-gradient(to top, rgba(8, 8, 8), rgb(0,0,0,0), rgb(0,0,0,0), rgb(0,0,0,0));
	content: "\00a0";
	height: 100%;
	position: absolute;
	width: 100%;
	pointer-events: none;
}

@media only screen and (max-width: 768px) {
	.tech {
		overflow: scroll;
		overflow-x: hidden;
	}

	.tech::before {
		display: none;
	}
}