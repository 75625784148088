.home {
	height: 100vh;
	min-width: 100vw;

	.row {
		min-width: 100vw;
		height: 100%;
		padding: 0;
		margin: 0;

		.header-col {
			display: flex;
			justify-content: center;
			padding: 0;
			margin: 0;

			.header-box {
				padding-top: 14rem;

				.subheading-box {
					display: flex;
					flex-direction: row;

					.line {
						border-top: 4px solid white;
						width: 30px;
						margin-right: 2rem;
						align-self: center;
					}
				}
			}

			@media only screen and (max-width: 768px) {
				.header-box {
					padding: 2rem 2.5rem 0 2.5rem;
					margin-bottom: 2rem;
				}
			}
		}

		@media only screen and (max-width: 768px) {
			.header-col {
				justify-content: start;
			}
		}

		.col-content {
			opacity: 0;
			background-image: url("../assets/images/jadon.jpg");
			background-size: cover;
			background-repeat: no-repeat;
			padding: 0;
			margin: 0;

			.overlay {
				background-color: rgba(8, 8, 8, 0.4);
				width: 100%;
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: space-evenly;
				align-items: center;

				p {
					font-size: 25px;
					text-shadow: 1px 1px 2px rgba(8, 8, 8, 0.2), 0 0 25px rgba(8, 8, 8, 0.2), 0 0 5px rgba(8, 8, 8, 0.2);
				}

				p:hover {
					cursor: default;
					transform: scale(1.1);
				}
			}
		}

		@media only screen and (max-width: 768px) {
			.col-content {
				height: 100%;
			}
		}
	}
}

@media only screen and (max-width: 768px) {
	.home {
		overflow: scroll;
		overflow-x: hidden;
	}
}
